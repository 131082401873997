export default function () {
  const infoDivClassName = 'img-info-div-debug'
  const showImagesSizes = () => {
    document.querySelectorAll(`.${infoDivClassName}`).forEach((element) => {
      element.remove()
    })

    const imgs = document.querySelectorAll('img')
    imgs.forEach((img) => {
      const infoDiv = document.createElement('div')
      infoDiv.style.position = 'absolute'
      infoDiv.style.top = '0'
      infoDiv.style.background = 'white'
      infoDiv.style.color = 'black'
      infoDiv.style.border = '1px solid black'
      infoDiv.style.textAlign = 'left'
      infoDiv.classList.add(infoDivClassName)

      const renderedWith = img.clientWidth
      const renderedHeight = img.clientHeight
      const loadedWidth = img.naturalWidth
      const loadedHeight = img.naturalHeight

      const infoDivRendered = document.createElement('div')
      infoDivRendered.innerHTML = `Rendered: ${renderedWith} x ${renderedHeight}`
      infoDiv.appendChild(infoDivRendered)

      const infoDivLoaded = document.createElement('div')
      infoDivLoaded.innerHTML = `Loaded: ${loadedWidth} x ${loadedHeight}`
      infoDiv.appendChild(infoDivLoaded)

      const infoDivColor = document.createElement('div')
      const objectFitContain = getComputedStyle(img).objectFit === 'contain'
      if (loadedWidth === 0 || loadedHeight === 0) {
        infoDivColor.style.backgroundColor = 'grey'
        infoDivColor.innerHTML =
          'Image loaded later. Resize window to recalculate.'
      } else if (
        objectFitContain &&
        loadedWidth < renderedWith &&
        loadedHeight < renderedHeight
      ) {
        infoDivColor.innerHTML = 'Loaded too small image'
        infoDivColor.style.backgroundColor = 'red'
      } else if (
        !objectFitContain &&
        (loadedWidth < renderedWith || loadedHeight < renderedHeight)
      ) {
        infoDivColor.innerHTML = 'Loaded too small image'
        infoDivColor.style.backgroundColor = 'red'
      } else if (
        (loadedWidth >= renderedWith && loadedWidth < renderedWith * 2) ||
        (loadedHeight >= renderedHeight && loadedHeight < renderedHeight * 2)
      ) {
        infoDivColor.style.backgroundColor = 'green'

        if (
          objectFitContain &&
          (loadedWidth < renderedWith || loadedHeight < renderedHeight)
        ) {
          infoDivColor.innerHTML =
            'For "object-fit: contain" only width OR height need to be large enough'
        } else {
          infoDivColor.innerHTML = 'Loaded image has the right size.'
        }
      } else {
        infoDivColor.innerHTML = 'Loaded too big image'
        infoDivColor.style.backgroundColor = 'yellow'
      }
      infoDivColor.style.minHeight = '1.5em'
      infoDiv.appendChild(infoDivColor)

      let parent = img.parentElement
      if (parent?.tagName.toLowerCase() === 'picture') {
        parent.after(infoDiv)
        parent = parent.parentElement
      } else {
        img.after(infoDiv)
      }

      if (parent) {
        const parentPosition =
          getComputedStyle(parent).getPropertyValue('position')
        if (!['relative', 'absolute'].includes(parentPosition)) {
          parent.style.position = 'relative'
        }
      }
    })
  }

  showImagesSizes()
  window.addEventListener('resize', showImagesSizes)

  // eslint-disable-next-line no-console
  console.log('Added rendering info to the images.')
}
