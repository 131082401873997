<template>
  <figure>
    <RokkaImage
      v-if="imageStyle && rokkaImageHash"
      :config="imageStyle"
      :file-name="rokkaFileName"
      :alt="image?.alt"
      :title="image?.title"
      :img-class="imgClass"
      :loading="loading"
      :preload="preload"
      :hash="rokkaImageHash"
    />
    <img v-else :src="imgSrc" :loading="loading" :class="imgClass" />
  </figure>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'
import { rokkaConfig } from '~/nuxtConfig/rokka'

type baseProps = {
  imgClass?: string
  eager?: boolean
}

type rokkaProps = {
  imageStyle?: DefineImageStyleConfig
  image?: MediaImageFragment['image']
  rokkaHash?: string // provide either 'image' or 'rokkaHash'

  /**
   * Preload the image. When set, the images are reploaded using a <link> tag.
   */
  preload?: boolean
}

type imgProps = {
  url?: MediaImageFragment['url'] | string
}

const props = defineProps<baseProps & rokkaProps & imgProps>()

defineOptions({
  name: 'MediaImage',
})

const actualUrl = computed(() => {
  if (props.url) {
    if (typeof props.url === 'string') {
      return props.url
    } else {
      return props.url.path
    }
  }

  return undefined
})

const loading = computed(() => (props.eager ? 'eager' : 'lazy'))

// Ensure the file ending is .jpg so rokka is allowed to deliver lossy versions.
// Best would be to have no file ending since rokka anyway does not return what we ask for,
// but with no file ending we get a 404.
// We have autoformat enabled on all our rokka stacks for the frontend.
const ensureJpg = (img: string | undefined) => {
  const pathArray = img?.split('/') ?? []
  const oldFilename = pathArray.pop()

  if (!oldFilename || !img) {
    return
  }

  const withoutEnding = oldFilename.replace(/(-v[0-9]+)?\.[^.]+$/, '')

  // if no version was specified. Add -v1 so new (smaller because of autoformat) images are delivered.
  let newFilename = withoutEnding
  if (!withoutEnding.match(/-v[0-9]+$/)) {
    newFilename += '-v1'
  }
  newFilename += '.jpg'

  const path = pathArray.join('/')
  return (path.length > 0 ? path + '/' : '') + newFilename
}

const imgSrc = computed(() => {
  if (rokkaImageHash.value) {
    // Use the 'dynamic' stack for images where we only provide the hash and no image style.
    // Our stacks 'fe_vuepal_crop' and 'fe_vuepal_no_crop' both need a provided with. And we
    // do not know the with of the image or which width we need here if no image style is provided.
    const rokkaUrl = buildRokkaUrl(
      'dynamic', // stack
      undefined, // dpr
      rokkaImageHash.value, // hash
      rokkaFileName.value, // name
    )
      .replace(/([^:])\/\//g, '$1/')
      .replace('/dynamic/', '/dynamic/options-autoformat-true/')

    return ensureJpg(rokkaUrl)
  }

  // only ensure jpg if we are on rokka and autoformat is true.
  const url = actualUrl.value ? new URL(actualUrl.value) : undefined
  if (
    url &&
    url.hostname.match(/rokka.io$/) &&
    (url.pathname.includes(rokkaConfig.stacks.crop) ||
      url.pathname.includes(rokkaConfig.stacks.noCrop) ||
      url.pathname.includes('autoformat-true'))
  ) {
    return ensureJpg(actualUrl.value)
  } else {
    return actualUrl.value
  }
})

const rokkaImageHash = computed(() => {
  let rokkaImageHash = props.image?.file?.rokka?.hash || props.rokkaHash

  if (!rokkaImageHash && actualUrl.value) {
    const url = new URL(actualUrl.value)

    if (url.hostname === 'image.oris.ch') {
      // this url is whitelisted at rokka to proxy urls from.
      rokkaImageHash = `-${actualUrl.value}-`
    }
  }

  return rokkaImageHash
})

const rokkaFileName = computed(() => {
  let rokkaFileName = props.image?.file?.label

  if (!rokkaFileName && actualUrl.value) {
    rokkaFileName = actualUrl.value.split('/').pop()
  }

  if (!rokkaFileName) {
    rokkaFileName = 'image.jpg'
  }

  return ensureJpg(rokkaFileName)
})
</script>
